import { createApp } from "vue";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Tooltip,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  TreeSelect,
  Table,
  Upload,
  Drawer,
  Skeleton,
  Empty,
  AutoComplete,
  Result,
  Steps,
  ConfigProvider,
} from "ant-design-vue";
import ABadgeRibbon from "ant-design-vue/lib/badge/Ribbon";
import Axios from "axios";
import "ant-design-vue/dist/antd.less";
import AppComponent from "./App.vue";
import router from "./router";
import { makeServer } from "./mock/server";
import { setupI18n } from "./i18n";
import { store, key } from "./store";
import { vueKeycloak } from "@baloise/vue-keycloak";
import { RuntimeConfig } from "../types";

export const axios = Axios;
const i18n = setupI18n();

(async () => {
  const runtimeConfig: RuntimeConfig = await fetch("/config.json").then(
    (response) => response.json()
  );

  if (runtimeConfig.mockup) {
    makeServer({
      apiUrl: runtimeConfig.apiUrl,
      authUrl: runtimeConfig.authUrl,
    });
  }

  runtimeConfig.authUrl = runtimeConfig.authUrl.trim();

  Axios.defaults.baseURL = runtimeConfig.apiUrl;

  const app = createApp(AppComponent);
  app.provide("http", Axios);
  app.provide("mockup", runtimeConfig.mockup);
  app.provide("authUrl", runtimeConfig.authUrl);
  app.provide("authClientId", runtimeConfig.authClientId);
  app.provide("authRealm", runtimeConfig.authRealm);
  app.provide("maintenance", !!runtimeConfig.maintenance);

  if (runtimeConfig.authUrl && !runtimeConfig.maintenance) {
    app.use(vueKeycloak, {
      config: {
        url: runtimeConfig.authUrl + "/auth",
        realm: runtimeConfig.authRealm,
        clientId: runtimeConfig.authClientId,
      },
      initOptions: {
        onLoad: "login-required",
      },
    });
  }

  app.use(store, key);
  app.use(router);
  app.use(i18n);
  app.use(Alert);
  app.use(TreeSelect);
  app.use(Breadcrumb);
  app.use(Button);
  app.use(Checkbox);
  app.use(Col);
  app.use(DatePicker);
  app.use(AutoComplete);
  app.use(ConfigProvider);
  app.use(Divider);
  app.use(Dropdown);
  app.use(Card);
  app.use(Form);
  app.use(Input);
  app.use(InputNumber);
  app.use(Layout);
  app.use(List);
  app.use(Menu);
  app.use(Tooltip);
  app.use(Modal);
  app.use(Pagination);
  app.use(Popconfirm);
  app.use(Popover);
  app.use(Progress);
  app.use(Radio);
  app.use(Row);
  app.use(Select);
  app.use(Slider);
  app.use(Spin);
  app.use(Table);
  app.use(Upload);
  app.use(Drawer);
  app.use(Skeleton);
  app.use(Empty);
  app.use(Steps);
  app.use(Result);
  app.component(ABadgeRibbon.name, ABadgeRibbon);
  app.mount("#app");
})();

import { Availability } from "../../../types";
import moment from "moment";

export const newAvailabilities: Array<Availability> = [
  {
    id: 4,
    range: {
      from: moment().startOf("week").add(1, "day").format("YYYY-MM-DDT10:00"),
      to: moment().startOf("week").add(1, "day").format("YYYY-MM-DDT16:45"),
    },
    status: "ACCEPTED",
    serviceExecutionType: ["CALL", "STATIONARY", "VIDEO"],
  },
  {
    id: 4,
    range: {
      from: moment().startOf("week").add(2, "day").format("YYYY-MM-DDT11:00"),
      to: moment().startOf("week").add(2, "day").format("YYYY-MM-DDT14:45"),
    },
    status: "REJECTED",
    serviceExecutionType: ["HOUSE"],
  },
  {
    id: 5,
    range: {
      from: moment().startOf("week").add(5, "day").format("YYYY-MM-DDT18:00"),
      to: moment().startOf("week").add(5, "day").format("YYYY-MM-DDT19:45"),
    },
    status: "PLANNED",
    serviceExecutionType: ["CALL", "CHAT", "VIDEO"],
  },
];

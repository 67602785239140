import { createServer, Response } from "@hd2/miragejs";
import visitTypes from "./data/visitTypes";
import { shortPatients } from "./data/patients";
import { filter, orderBy } from "lodash";
import {
  RegisterStatus,
  ResponseList,
  Visit,
  VisitStatus,
  VisitType,
  NfzStatus,
  Conversations,
} from "../../types";
import {
  paidVisits,
  canceledByDoctorVisits,
  finishedVisits,
  finishedNoContactVisits,
} from "./data/visits";
import productCodes from "./data/productCodes";
import { newAvailabilities } from "./data/availabilities";
import { visitStatuses } from "./data/visitStatuses";
import { statusReasons, nfzStatusReasons } from "./data/statusReasons";
import profile from "./data/profile";
import langs from "./data/langs";
import {
  personal as registerPersonal,
  contract as registerContract,
  specializations as registerSpecializations,
} from "./data/register";
import contractTypes from "./data/contractTypes";
import academicTitles from "./data/academicTitles";
import { url as certUrl } from "./data/certificate";
import { url as visitUrl } from "./data/visit";
import { conversationPatient, conversationDoctor } from "./data/conversation";
import { conversations } from "./data/conversations";
import {
  officalSpecializations,
  specializationsStatuses,
  visitSpecializations,
} from "./data/specializations";

import billing from "./data/billing";

interface Sort {
  field: string;
  order: "asc" | "desc";
}

const registerStatus: RegisterStatus = "FULLY_REGISTERED";

export async function makeServer({
  environment = "development",
  apiUrl = "/",
  authUrl = "",
} = {}) {
  let chatIterator = 2;
  const server = createServer({
    environment,
    timing: 1000,
    async: environment === "test" ? false : true,
    routes() {
      this.urlPrefix = apiUrl;
      this.pretender.get("data:**", this.pretender.passthrough);
      if (authUrl) {
        this.passthrough(authUrl + "/**");
      }
      this.namespace = "";
      this.get("v1/visits/types", () => {
        const headers = {};
        return new Response(200, headers, visitTypes);
      });
      this.get("v1/doctors/certificate", () => {
        const headers = {};
        return new Response(200, headers, certUrl);
      });
      this.get("v1/visits/:id/start", () => {
        const headers = {};
        return new Response(200, headers, visitUrl);
      });
      this.get("v1/doctors/dictionary/contract-types", () => {
        const headers = {};
        return new Response(200, headers, contractTypes);
      });
      this.get("v1/doctors/dictionary/academic-titles", () => {
        const headers = {};
        return new Response(200, headers, academicTitles);
      });
      this.get("v1/visits/final-statuses", () => {
        const headers = {};
        return new Response(200, headers, visitStatuses);
      });
      this.get("v1/visits/:id/status-reasons", (schema, request) => {
        const headers = {};
        const id = request.params.id;
        const visit = paidVisits.find((elem) => {
          return elem.id == Number(id);
        });
        if (visit && visit.details.nfzVisit == true) {
          return new Response(200, headers, nfzStatusReasons);
        } else {
          return new Response(200, headers, statusReasons);
        }
      });
      this.get("v1/visits/patients/autocomplete", (schema, request) => {
        const headers = {};
        const pesel = String(request.queryParams.pesel);
        const res = filter(shortPatients, (shortPatient) => {
          return shortPatient.pesel.startsWith(pesel);
        });
        return new Response(200, headers, res);
      });
      this.put("v1/visits/:id/status", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/visits/product-codes", () => {
        const headers = {};
        return new Response(200, headers, productCodes);
      });
      this.put("v1/visits/:id/change-nfz-code", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.post("v1/visits/:id/confirm/nfz", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/:id/profile", () => {
        const headers = {};
        return new Response(200, headers, profile);
      });
      this.patch("v1/doctors/:id/profile", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/dictionary/languages", () => {
        const headers = {};
        return new Response(200, headers, langs);
      });
      this.get("v1/doctors/dictionary/specializations", () => {
        const headers = {};
        return new Response(200, headers, visitSpecializations);
      });
      this.get("v1/doctors/dictionary/official-specializations", () => {
        const headers = {};
        return new Response(200, headers, officalSpecializations);
      });
      this.get("v1/doctors/dictionary/specialization-statuses", () => {
        const headers = {};
        return new Response(200, headers, specializationsStatuses);
      });
      this.post("v1/doctors/:id/register", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/:id/register", () => {
        const headers = {};
        return new Response(200, headers, registerStatus);
      });
      this.put("v1/doctors/:id/register/personal", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/:id/register/personal", () => {
        const headers = {};
        return new Response(200, headers, registerPersonal);
      });
      this.get("v1/doctors/settlement", () => {
        const headers = {};
        return new Response(200, headers, billing);
      });
      this.get("/v1/doctors/availabilities", () => {
        const headers = {};
        return new Response(200, headers, newAvailabilities);
      });
      this.post("/v1/doctors/availabilities/:id", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.post("/v1/doctors/availabilities", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.patch("v1/doctors/:id/calendars", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.put("v1/doctors/:id/register/contract", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/:id/register/contract", () => {
        const headers = {};
        return new Response(200, headers, registerContract);
      });
      this.put("v1/doctors/:id/register/specializations", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("v1/doctors/:id/register/specializations", () => {
        const headers = {};
        return new Response(200, headers, registerSpecializations);
      });
      this.get("v1/visits/:id/patient-nfz", () => {
        const headers = {};
        return new Response(200, headers, {
          status: "UNKNOWN",
          confirmedByPatient: true,
        } as NfzStatus);
      });
      this.get("v1/visits/:id", () => {
        const headers = {};
        return new Response(200, headers, paidVisits[0]);
      });
      this.get("v1/visits", (schema, request) => {
        const headers = {};

        const page = Number(request.queryParams.page) || 0;
        const pageSize = Number(request.queryParams.size) || 5;
        const date =
          request.queryParams.date !== "null" ? request.queryParams.date : null;
        const pesel = request.queryParams.pesel;
        const visitType: Array<VisitType> = request.queryParams.visitType
          ? (request.queryParams.visitType.split(",") as Array<VisitType>)
          : [];
        const visitStatuses: Array<VisitStatus> = request.queryParams[
          "statuses"
        ]
          ? (request.queryParams["statuses"].split(",") as Array<VisitStatus>)
          : [];

        const res: ResponseList<Visit> = {
          pageable: {
            pageNumber: page,
          },
          totalElements: 0,
          size: pageSize,
          content: [],
        };

        const mapStatuses = {
          PAID: paidVisits,
          CANCELLED_BY_DOCTOR: canceledByDoctorVisits,
          FINISHED: finishedVisits,
          FINISHED_NO_CONTACT: finishedNoContactVisits,
          WAITING_FOR_PAYMENT: [],
          NOT_REALIZED: [],
        };

        visitStatuses.forEach((visitStatus) => {
          res.content = res.content.concat(mapStatuses[visitStatus]);
        });

        if (date) {
          res.content = res.content.filter((visit) => {
            return visit.from?.includes(date);
          });
        }

        if (pesel) {
          res.content = res.content.filter((visit) => {
            return visit.patients[0].pesel.startsWith(pesel);
          });
        }

        if (visitType.length > 0) {
          res.content = res.content.filter((visit) => {
            return visitType.includes(visit.details.type);
          });
        }

        if (visitStatuses.length > 0) {
          res.content = res.content.filter((visit) => {
            return visitStatuses.includes(visit.status);
          });
        }

        if (request.queryParams.sort) {
          const sort: Sort = {
            field: "",
            order: "asc",
          };
          sort.field = request.queryParams.sort.split(",")[0];
          switch (request.queryParams.sort.split(",")[1]) {
            case "ASC": {
              sort.order = "asc";
              break;
            }
            case "DESC": {
              sort.order = "desc";
              break;
            }
          }

          res.content = orderBy(
            res.content,
            [
              function (o: Visit) {
                switch (sort.field) {
                  case "date": {
                    return new Date(o.from ?? 0);
                  }
                  default: {
                    return new Date(o.from ?? 0);
                  }
                }
              },
            ],
            [sort.order]
          );
        }

        res.totalElements = res.content.length;
        res.content = res.content.slice(
          page * pageSize,
          page * pageSize + pageSize
        );
        res.pageable.pageNumber = page;
        res.size = pageSize;

        return new Response(200, headers, res);
      });
      this.get("v1/conversations", (schema, request) => {
        const headers = {};

        const page = Number(request.queryParams.page) || 0;
        const pageSize = Number(request.queryParams.size) || 5;
        const res: ResponseList<Conversations> = {
          pageable: {
            pageNumber: page,
          },
          totalElements: 0,
          size: pageSize,
          content: conversations,
        };

        const messageStatus: string = request.queryParams["status"];
        if (messageStatus) {
          res.content = res.content.filter((message) => {
            return message.status == messageStatus;
          });
        }
        res.totalElements = res.content.length;
        res.content = res.content.slice(
          page * pageSize,
          page * pageSize + pageSize
        );
        res.pageable.pageNumber = page;
        res.size = pageSize;

        return new Response(200, headers, res);
      });
      this.get("v1/conversations/:id", () => {
        const headers = {};
        chatIterator++;
        return new Response(
          200,
          headers,
          chatIterator % 2 ? conversationPatient : conversationDoctor
        );
      });
      this.post("v1/conversations/:id/message", () => {
        const headers = {};
        return new Response(200, headers);
      });
    },
  });

  return server;
}

import { Specializations, SpecializationsStatus } from "../../../types";

export const officalSpecializations: Specializations = {
  ALLERGOLOGY: 1,
  ANESTHESIOLOGY_AND_INTENSIVE_CARE: 2,
  ANGIOLOGY: 3,
  AUDIOLOGY_AND_PHONIATRICS: 4,
  BALNEOLOGY_AND_PHYSICAL_MEDICINE: 5,
  PEDIATRIC_SURGERY: 6,
  THORACIC_SURGERY: 7,
  VASCULAR_SURGERY: 8,
  GENERAL_SURGERY: 9,
  ONCOLOGICAL_SURGERY: 10,
  COSMETIC_SURGERY: 11,
  ORAL_SURGERY: 12,
  LUNG_DISEASE: 13,
  LUNG_DISEASE_CHILDREN: 14,
  INTERNAL_MEDICINE: 15,
  INFECTIOUS_DISEASES: 16,
  DERMATOLOGY_AND_VENEREOLOGY: 17,
  DIABETOLOGY: 18,
  LABORATORY_DIAGNOSTICS: 19,
  ENDOCRINOLOGY: 20,
  GYNECOLOGICAL_ENDOCRINOLOGY_AND_REPRODUCTION: 21,
  ENDOCRINOLOGY_AND_DIABETOLOGY_CHILDREN: 22,
  EPIDEMIOLOGY: 23,
  CLINICAL_PHARMACOLOGY: 24,
  GASTROENTEROLOGY: 25,
  CHILDRENS_GASTROENTEROLOGY: 26,
  CLINICAL_GENETICS: 27,
  GERIATRICS: 28,
  GYNECOLOGY_ONCOLOGY: 29,
  HEMATOLOGY: 30,
  HYPERTENSIOLOGY: 31,
  CLINICAL_IMMUNOLOGY: 32,
  INTENSIVE_CARE: 33,
  CARDIAC_SURGERY: 34,
  CARDIOLOGY: 35,
  CHILDRENS_CARDIOLOGY: 36,
  AVIATION_MEDICINE: 37,
  SEA_AND_TROPICAL_MEDICINE: 38,
  NUCLEAR_MEDICINE: 39,
  PALLIATIVE_MEDICINE: 40,
  OCCUPATIONAL_MEDICINE: 41,
  EMERGENCY_MEDICINE: 42,
  FAMILY_MEDICINE: 43,
  FORENSIC_MEDICINE: 44,
  SPORTS_MEDICINE: 45,
  MEDICAL_MICROBIOLOGY: 46,
  NEPHROLOGY: 47,
  CHILDREN_NEPHROLOGY: 48,
  NEONATOLOGY: 49,
  NEUROSURGERY: 50,
  NEUROLOGY: 51,
  CHILDRENS_NEUROLOGY: 52,
  NEUROPATHOLOGY: 53,
  OPHTHALMOLOGY: 54,
  CHILDREN_ONCOLOGY_AND_HEMATOLOGY: 55,
  CLINICAL_ONCOLOGY: 56,
  ORTHOPEDICS_AND_TRAUMATOLOGY_MUSCULOSKELETAL: 57,
  OTORHINOLARYNGOLOGY: 58,
  CHILDRENS_OTORHINOLARYNGOLOGY: 59,
  PATHOLOGY: 60,
  PEDIATRICS: 61,
  METABOLIC_PEDIATRICS: 62,
  PERINATOLOGY: 63,
  OBSTETRICS_AND_GYNECOLOGY: 64,
  PSYCHIATRY: 65,
  CHILDREN_AND_YOUTH_PSYCHIATRY: 66,
  RADIOLOGY_AND_DIAGNOSTIC_IMAGING: 67,
  RADIATION_ONCOLOGY: 68,
  MEDICAL_REHABILITATION: 69,
  RHEUMATOLOGY: 70,
  SEXOLOGY: 71,
  CLINICAL_TOXICOLOGY: 72,
  CLINICAL_TRANSFUSION: 73,
  CLINICAL_TRANSPLANTATION: 74,
  UROLOGY: 75,
  CHILDRENS_UROLOGY: 76,
  PUBLIC_HEALTH: 77,
  DIETETICS: 80,
  PSYCHOLOGY: 81,
  OBSTETRICS: 82,
};

export const visitSpecializations: Specializations = {
  SURGEON: 4,
  INTERNIST: 21,
  PEDIATRICIAN: 22,
  ALLERGIST: 9,
  ALLERGIST_CHILDREN: 37,
  ANGIOLOGIST: 46,
  ANGIOLOGIST_CHILDREN: 47,
  SURGEON_CHILDREN: 33,
  THORACIC_SURGEON: 48,
  VASCULAR_SURGEON: 49,
  ONCOLOGICAL_SURGEON: 50,
  PLASTIC_SURGEON: 51,
  MAXILLOFACIAL_SURGEON: 52,
  DERMATOLOGIST: 8,
  DERMATOLOGIST_CHILDREN: 40,
  DIABETOLOGIST: 11,
  DIABETOLOGIST_CHILDREN: 43,
  LABORATORY_DIAGNOSTICIAN: 53,
  DIETICIAN: 6,
  DIETICIAN_CHILDREN: 32,
  ENDOCRINOLOGIST: 1,
  ENDOCRINOLOGIST_GYNECOLOGIST: 54,
  ENDOCRINOLOGIST_CHILDREN: 34,
  GASTROLOGIST: 16,
  GASTROLOGIST_CHILDREN: 41,
  CLINICAL_GENETICIST: 55,
  GERIATRICIAN: 24,
  GYNECOLOGIST: 2,
  GYNECOLOGIST_ONCOLOGIST: 56,
  GYNECOLOGIST_OBSTETRICIAN: 57,
  HEMATOLOGIST: 58,
  HYPERTENSIOLOGIST: 59,
  HYPERTENSIOLOGIST_CHILDREN: 60,
  IMMUNOLOGIST: 61,
  IMMUNOLOGIST_CHILDREN: 62,
  CARDIAC_SURGEON: 63,
  CARDIAC_SURGEON_CHILDREN: 64,
  CARDIOLOGIST: 13,
  CARDIOLOGIST_CHILDREN: 42,
  LARYNGOLOGIST: 14,
  LARYNGOLOGIST_CHILDREN: 25,
  INFECTIOUS_DISEASE_DOCTOR: 65,
  INFECTIOUS_DISEASE_DOCTOR_CHILDREN: 66,
  NUCLEAR_MEDICINE_DOCTOR: 67,
  PALLIATIVE_MEDICINE_DOCTOR: 68,
  TRAVEL_MEDICINE: 10,
  TRAVEL_MEDICINE_CHILDREN: 39,
  OCCUPATIONAL_PHYSICIAN: 69,
  FAMILY_MEDICINE: 35,
  SPORTS_MEDICINE_DOCTOR: 70,
  SPORTS_MEDICINE_DOCTOR_CHILDREN: 71,
  MICROBIOLOGIST: 72,
  MEDICAL_REHABILITATION_DOCTOR: 73,
  MEDICAL_REHABILITATION_DOCTOR_CHILDREN: 74,
  NEPHROLOGIST: 23,
  NEPHROLOGIST_CHILDREN: 26,
  NEONATOLOGIST: 75,
  NEUROSURGEON: 76,
  NEUROSURGEON_CHILDREN: 77,
  NEUROLOGIST: 17,
  NEUROLOGIST_CHILDREN: 27,
  OCULIST: 78,
  OCULIST_CHILDREN: 79,
  ONCOLOGIST: 12,
  ONCOLOGIST_RADIOTHERAPIST: 80,
  ONCOLOGIST_CHILDREN: 28,
  ORTHOPAEDIST: 3,
  ORTHOPAEDIST_CHILDREN: 36,
  MIDWIFE: 7,
  PSYCHIATRIST: 44,
  PSYCHIATRIST_CHILDREN: 45,
  PSYCHOLOGIST: 5,
  PSYCHOLOGIST_CHILDREN: 29,
  PULMONOLOGIST: 15,
  PULMONOLOGIST_CHILDREN: 38,
  RADIOLOGIST: 81,
  RHEUMATOLOGIST: 20,
  RHEUMATOLOGIST_CHILDREN: 30,
  SEXOLOGIST: 82,
  SEXOLOGIST_CHILDREN: 83,
  TOXICOLOGIST: 84,
  TRANSPLANTOLOGIST: 85,
  UROLOGIST: 18,
  UROLOGIST_CHILDREN: 31,
};

export const specializationsStatuses: Array<SpecializationsStatus> = [
  "IN_PROGRESS",
  "DONE",
];

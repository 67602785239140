import { StatusReason } from "../../../types";

export const statusReasons: Array<StatusReason> = [
  "CANCELLED_BY_PATIENT",
  "INCORRECT_DATA",
  "SYSTEM_FAIL",
];

export const nfzStatusReasons: Array<StatusReason> = [
  "CANCELLED_BY_PATIENT",
  "INCORRECT_DATA",
  "SYSTEM_FAIL",
  "NO_NFZ_INSURANCE",
];

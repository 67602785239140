import { notification } from "ant-design-vue";
import { each } from "lodash";
import { useI18n } from "vue-i18n";

export const useShowErrors = () => {
  const { t } = useI18n();
  const showErrors = (errors: Error) => {
    each(errors, (fieldErrors) => {
      if (Array.isArray(fieldErrors)) {
        each(fieldErrors, (error) => {
          if (typeof error === "string") {
            notification.open({
              message: t(`ERROR.${error.toUpperCase()}`),
              class: "error",
            });
          }
        });
      }
    });
  };
  return { showErrors };
};

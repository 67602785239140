import {
  RegisterPersonal,
  RegisterContract,
  RegisterSpecialization,
} from "../../../types";

export const personal: RegisterPersonal = {
  photo:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=",
  firstName: "Test",
  lastName: "Test",
  pesel: "12121251212",
  phone: {
    number: "000000000",
    prefix: "+48",
  },
  nativeLanguageId: 0,
  foreignLanguageIds: [1],
  address: {
    home: {
      street: "Jasna",
      houseNumber: "12",
      flatNumber: "4",
      postCode: "61-864",
      city: "Poznań",
    },
    mailing: {
      street: "Rolna",
      houseNumber: "11",
      postCode: "64-333",
      city: "Poznań",
    },
    secondary: {
      street: "Konwaliowa",
      houseNumber: "56",
      flatNumber: "3",
      postCode: "45-333",
      city: "Poznań",
    },
  },
};

export const contract: RegisterContract = {
  contractType: "INDIVIDUAL_PRACTITION_ONLY_STATIONARY",
  academicTitle: "DR_HAB_N_MED",
  pwz: {
    number: "123456789",
    file: "data:image/png;base64,W29iamVjdCBPYmplY3Rd",
    fileName: "example.ext",
  },
  authorityForMedicalCerts: true,
  insurance: {
    dateFrom: "2021-11-05",
    dateTo: "2030-11-05",
    amount: {
      value: 1000000,
      currency: "PLN",
    },
    file: "data:image/png;base64,W29iamVjdCBPYmplY3Rd",
    fileName: "example.ext",
  },
  company: {
    name: "test",
    nip: "5325814289",
    address: {
      street: "Rogowa",
      houseNumber: "3",
      postCode: "22-222",
      city: "Warszawa",
    },
  },
  specializations: [
    {
      id: 1,
      status: "DONE",
    },
    {
      id: 2,
      status: "DONE",
    },
  ],
};
export const specializations: RegisterSpecialization = {
  consultationIds: [2, 3],
  consultationTypes: ["HOUSE", "CALL"],
};

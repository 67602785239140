import { VisitType } from "../../../types";

const visitTypes: Array<VisitType> = [
  "CALL",
  "HOUSE",
  "CHAT",
  "STATIONARY",
  "VIDEO",
];

export default visitTypes;

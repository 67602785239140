import { ProductCode } from "../../../types";

const productCodes: Array<ProductCode> = [
  {
    code: "5.01.00.0000121",
    description: "porada lekarska udzielona w miejscu udzielania świadczeń",
  },
  {
    code: "5.01.00.0000122",
    description: "porada lekarska udzielona w domu pacjenta",
  },
  {
    code: "5.01.00.0000046",
    description:
      "porada lekarska udzielona osobie spoza listy świadczeniobiorców zamieszkałej na terenie tego samego województwa ale poza gminą własną i sąsiadującymi z tym miejscem lub osobie spoza listy świadczeniobiorców zamieszkałej na terenie innego województwa",
  },
  {
    code: "5.01.00.0000047",
    description:
      "porada lekarska udzielona osobie spoza listy świadczeniobiorców uprawnionej na podstawie przepisów o koordynacji",
  },
  {
    code: "5.01.00.0000048",
    description: "świadczenie w ramach profilaktyki chorób układu krążenia",
  },
  {
    code: "5.01.00.0000051",
    description:
      "porada kwalifikacyjna do transportu sanitarnego ,,dalekiego” w poz",
  },
  {
    code: "5.01.00.0000079",
    description:
      "porada lekarska udzielona świadczeniobiorcy innemu niż ubezpieczony uprawnionemu do świadczeń zgodnie z treścią art. 2 ust. 1 pkt. 2-4 oraz art. 54 ustawy lub wyłącznie na podstawie przepisów, o których mowa w art. 12 pkt 6 i 9 ustawy lub obcokrajowcowi posiadającemu ubezpieczenie zdrowotne na podstawie zgłoszenia, w związku z czasowym zatrudnieniem na terytorium Rzeczypospolitej Polskiej",
  },
  {
    code: "5.01.00.0000102",
    description:
      "porada patronażowa lekarza poz zrealizowana w miejscu udzielania świadczeń",
  },
  {
    code: "5.01.00.0000103",
    description:
      "porada patronażowa lekarza poz zrealizowana w domu świadczeniobiorcy",
  },
  {
    code: "5.01.00.0000104",
    description: "bilans zdrowia",
  },
  {
    code: "5.01.00.0000001",
    description: "porada lekarska związana z wydaniem karty DiLO",
  },
  {
    code: "5.01.00.0000149",
    description:
      "porada lekarska związana z wydaniem zaświadczenia osobie niezdolnej do samodzielnej egzystencji",
  },
  {
    code: "5.01.00.0000152",
    description: "teleporada lekarza poz",
  },
  {
    code: "5.01.00.0000155",
    description: "porada receptowa lekarza poz",
  },
];

export default productCodes;


import { MenuOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { defineComponent, ref, watch } from "vue";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import { useKeycloak } from "@baloise/vue-keycloak";

export const HeaderComponent = defineComponent({
  components: {
    MenuOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { keycloak } = useKeycloak();

    const { t } = useI18n();

    const currentPage = ref<string[]>([route.path?.toString()]);

    watch(
      () => route.path,
      () => {
        currentPage.value = [route.path?.toString()];
      }
    );

    const visibleMobileMenu = ref(false);

    const changeRoute = ({ key }: MenuInfo) => {
      visibleMobileMenu.value = false;
      router.push({ path: key.toString() });
    };

    const logout = async () => {
      keycloak.logout();
    };

    return {
      logout,
      changeRoute,
      currentPage,
      store,
      visibleMobileMenu,
      t,
    };
  },
});

export default HeaderComponent;

import { Patient } from "../../../types";

export const shortPatients: Array<Omit<Patient, "phone">> = [
  {
    id: 0,
    firstName: "Jan",
    lastName: "Kowalski",
    pesel: "121251212",
  },
  {
    id: 1,
    firstName: "Janusz",
    lastName: "Kowalski",
    pesel: "0947215434",
  },
  {
    id: 2,
    firstName: "Darek",
    lastName: "Kowalski",
    pesel: "123456789",
  },
];

export const patients: Array<Patient> = [
  {
    id: 0,
    firstName: "Jan",
    lastName: "Kowalski",
    pesel: "121251212",
    phone: "+48 123456789",
  },
  {
    id: 1,
    firstName: "Karol",
    lastName: "Kowalski",
    pesel: "0947215434",
    phone: "+48 123456789",
  },
  {
    id: 2,
    firstName: "Darek",
    lastName: "Kowalski",
    pesel: "123456789",
    phone: "+48 123456789",
  },
];

export const getPatient = (id: number): Patient => {
  return patients[id];
};

import { Langs } from "../../../types";

const langs: Langs = {
  PL: 0,
  EN: 1,
  UA: 2,
  RU: 3,
  DE: 4,
};

export default langs;

import { AcademicTitle } from "../../../types";

const academicTitles: Array<AcademicTitle> = [
  "TECH",
  "LIC",
  "MGR",
  "LEK",
  "LEK_DEN",
  "DR_N_MED",
  "DR_HAB_N_MED",
  "PROF",
];

export default academicTitles;

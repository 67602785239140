import { Conversations } from "../../../types";

export const conversations: Array<Conversations> = [
  {
    patientFullName: "Mariusz Kowalski",
    status: "UNANSWERED",
    visitDate: "2020-10-11",
    questionDate: "2020-10-11",
    conversationId: 0,
  },
  {
    patientFullName: "Katarzyna Kowalczyk",
    status: "UNANSWERED",
    visitDate: "2020-10-09",
    questionDate: "2020-10-09",
    conversationId: 0,
  },
  {
    patientFullName: "Halina Nowacka",
    status: "UNANSWERED",
    visitDate: "2020-10-08",
    questionDate: "2020-10-08",
    conversationId: 0,
  },
  {
    patientFullName: "Mariusz Kowalski",
    status: "ANSWERED",
    visitDate: "2020-10-20",
    questionDate: "2020-10-20",
    conversationId: 0,
  },
  {
    patientFullName: "Mariusz Kowalski",
    status: "ANSWERED",
    visitDate: "2020-10-20",
    questionDate: "2020-10-20",
    conversationId: 0,
  },
  {
    patientFullName: "Mariusz Kowalski",
    status: "ANSWERED",
    visitDate: "2020-10-20",
    questionDate: "2020-10-20",
    conversationId: 0,
  },
  {
    patientFullName: "Katarzyna Kowalczyk",
    status: "ANSWERED",
    visitDate: "2020-10-19",
    questionDate: "2020-10-19",
    conversationId: 0,
  },
  {
    patientFullName: "Katarzyna Kowalczyk",
    status: "ANSWERED",
    visitDate: "2020-10-19",
    questionDate: "2020-10-19",
    conversationId: 0,
  },
  {
    patientFullName: "Halina Nowacka",
    status: "ANSWERED",
    visitDate: "2020-10-18",
    questionDate: "2020-10-18",
    conversationId: 0,
  },
  {
    patientFullName: "Halina Nowacka",
    status: "ANSWERED",
    visitDate: "2020-10-18",
    questionDate: "2020-10-18",
    conversationId: 0,
  },
  {
    patientFullName: "Adam Niewiadomy",
    status: "ANSWERED",
    visitDate: "2020-10-12",
    questionDate: "2020-10-12",
    conversationId: 0,
  },
];

import { Conversation } from "../../../types";

export const conversationPatient: Conversation = {
  status: "UNANSWERED",
  visitDate: "2020-10-19",
  messages: [
    {
      role: "PATIENT",
      senderName: "Konrad Kowalski",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta faucibus neque eu tempus. Praesent sed nulla aliquet, elementum ex scelerisque, consequat dui. Sed mollis justo vel enim eleifend consectetur. Morbi justo velit, eleifend eu risus in, imperdiet rutrum nisl. Curabitur neque magna, ullamcorper faucibus aliquet non, mollis a nibh. Nam facilisis dictum magna ut facilisis. Vivamus ac risus finibus, luctus urna id, luctus tortor. Fusce augue felis, maximus vel molestie vitae, mollis eget justo. Vivamus dictum metus non lectus tempor, sit amet bibendum risus tincidunt. Fusce fringilla fermentum tortor a lobortis. Phasellus sed pellentesque nunc. Mauris turpis ligula, molestie et orci bibendum, imperdiet finibus turpis. Cras pharetra nisl et lacus imperdiet, vel porta ex vestibulum. Quisque hendrerit nunc dui, at fermentum urna mattis a. Aenean ex libero, mollis ullamcorper massa ut, finibus maximus neque. Aenean tellus lectus, ultricies nec tempus ut, elementum sit amet sem.      ",
      deliveredOn: "2020-10-19T13:20:33.118",
    },
  ],
};

export const conversationDoctor: Conversation = {
  status: "UNANSWERED",
  visitDate: "2020-10-19",
  messages: [
    {
      role: "PATIENT",
      senderName: "Konrad Kowalski",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta faucibus neque eu tempus. Praesent sed nulla aliquet, elementum ex scelerisque, consequat dui. Sed mollis justo vel enim eleifend consectetur. Morbi justo velit, eleifend eu risus in, imperdiet rutrum nisl. Curabitur neque magna, ullamcorper faucibus aliquet non, mollis a nibh. Nam facilisis dictum magna ut facilisis. Vivamus ac risus finibus, luctus urna id, luctus tortor. Fusce augue felis, maximus vel molestie vitae, mollis eget justo. Vivamus dictum metus non lectus tempor, sit amet bibendum risus tincidunt. Fusce fringilla fermentum tortor a lobortis. Phasellus sed pellentesque nunc. Mauris turpis ligula, molestie et orci bibendum, imperdiet finibus turpis. Cras pharetra nisl et lacus imperdiet, vel porta ex vestibulum. Quisque hendrerit nunc dui, at fermentum urna mattis a. Aenean ex libero, mollis ullamcorper massa ut, finibus maximus neque. Aenean tellus lectus, ultricies nec tempus ut, elementum sit amet sem.      ",
      deliveredOn: "2020-10-19T13:20:33.118",
    },
    {
      role: "DOCTOR_CHAT",
      senderName: "Jan Kowalski",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta faucibus neque eu tempus. Praesent sed nulla aliquet, elementum ex scelerisque, consequat dui. Sed mollis justo vel enim eleifend consectetur. Morbi justo velit, eleifend eu risus in, imperdiet rutrum nisl. Curabitur neque magna, ullamcorper faucibus aliquet non, mollis a nibh. Nam facilisis dictum magna ut facilisis. Vivamus ac risus finibus, luctus urna id, luctus tortor. Fusce augue felis, maximus vel molestie vitae, mollis eget justo. Vivamus dictum metus non lectus tempor, sit amet bibendum risus tincidunt. Fusce fringilla fermentum tortor a lobortis. Phasellus sed pellentesque nunc. Mauris turpis ligula, molestie et orci bibendum, imperdiet finibus turpis. Cras pharetra nisl et lacus imperdiet, vel porta ex vestibulum. Quisque hendrerit nunc dui, at fermentum urna mattis a. Aenean ex libero, mollis ullamcorper massa ut, finibus maximus neque. Aenean tellus lectus, ultricies nec tempus ut, elementum sit amet sem.        ",
      deliveredOn: "2020-10-19T15:20:33.118",
    },
  ],
};


import { defineComponent, ref, Ref, watch, reactive, inject } from "vue";
import { useI18n } from "vue-i18n";
import { Profile } from "../../types";
import { useStore } from "../store";
import { useRouter } from "vue-router";
import { CheckUserAgent } from "@hd2/common/src/utils";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { AxiosStatic } from "axios";

interface Model {
  showCertificate: Profile["showCertificate"];
}

export const CertPopupComponent = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const store = useStore();
    const router = useRouter();

    const isSupported =
      !CheckUserAgent.isSafari() && !CheckUserAgent.isOperaMobile();
    const { showErrors } = useShowErrors();

    const model: Model = reactive({
      showCertificate: false,
    });
    const visible: Ref<boolean> = ref(false);

    const cancel = () => {
      emit("update:modelValue", false);
    };

    const addCert = async () => {
      cancel();
      if (isSupported) {
        router.push({ path: "/certification" });
      } else {
        const windowReference = window.open("#");
        try {
          await http
            .get(`v1/doctors/certificate`)
            .then((res) => windowReference?.location.assign(res.data));
        } catch (err: any) {
          showErrors(err.response?.data);
        }
      }
    };

    const onShowCertChange = async () => {
      http.patch(`v1/doctors/${store.state.user.id}/profile`, {
        showCertificate: !model.showCertificate,
      });

      store.commit("setUser", { ...store.state.user, showCertificate: false });

      emit("update:modelValue", false);
    };

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    return {
      cancel,
      model,
      visible,
      t,
      onShowCertChange,
      addCert,
    };
  },
});

export default CertPopupComponent;

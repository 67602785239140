import { ContractType } from "../../../types";

const contractTypes: Array<ContractType> = [
  "INDIVIDUAL_PRACTITION",
  "INDIVIDUAL_PRACTITION_ONLY_CALLED",
  "INDIVIDUAL_PRACTITION_ONLY_STATIONARY",
  "INDIVIDUAL_SPECIALISTIC_PRACTITION",
  "INDIVIDUAL_SPECIALISTIC_PRACTITION_ONLY_CALLED",
  "INDIVIDUAL_SPECIALISTIC_PRACTITION_ONLY_STATIONARY",
  "GROUP_PRACTITION_AS_CIVIL_PARTNERSHIP",
  "GROUP_PRACTITION_AS_GENERAL_PARTNERSHIP",
  "GROUP_PRACTITION_AS_PROFESSIONAL_PARTNERSHIP",
];

export default contractTypes;

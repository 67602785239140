import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { AxiosStatic } from "axios";
import { inject } from "vue";
import { useStore } from "../store";

export const useSetIntervals = () => {
  const { showErrors } = useShowErrors();
  const store = useStore();
  const http = inject("http") as AxiosStatic;
  const setIntervalNewConversations = () => {
    const getConversations = async () => {
      try {
        await http
          .get(`v1/conversations?page=0&size=1&status=UNANSWERED`)
          .then((res) => {
            if (res.data.content && res.data.content.length > 0) {
              store.commit("setMessageAvailability", true);
            } else {
              store.commit("setMessageAvailability", false);
            }
          });
      } catch (err: any) {
        console.log(err);
        showErrors(err.response?.data);
        throw err.response?.data;
      }
    };
    const start = (ms: number) => {
      getConversations();
      return setInterval(() => {
        getConversations();
      }, ms);
    };
    const stop = (interval: NodeJS.Timer) => {
      clearInterval(interval);
    };
    return {
      start,
      stop,
    };
  };
  return { setIntervalNewConversations };
};

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.t('CERT_POPUP.HEADER'),
    class: "cert-popup",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    onCancel: _ctx.cancel,
    closable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        type: "tertiary",
        class: "cert-popup__submit",
        onClick: _ctx.addCert
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("CERT_POPUP.ADD_CERT")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_a_button, {
        class: "cert-popup__cancel",
        type: "secondary",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("CERT_POPUP.CLOSE")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.t("CERT_POPUP.DESC")), 1 /* TEXT */),
      _createVNode(_component_a_checkbox, {
        class: "cert-popup__dont-show-again",
        onChange: _ctx.onShowCertChange,
        checked: _ctx.model.showCertificate,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.showCertificate) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("CERT_POPUP.DONT_SHOW_AGAIN")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onChange", "checked"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "visible", "onCancel"]))
}
import { Profile } from "../../../types";

const profile: Profile = {
  firstName: "Jan",
  lastName: "Kowalski",
  academicTitle: "DR_HAB_N_MED",
  email: "test@test.com",
  showCertificate: true,
};

export default profile;

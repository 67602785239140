import { RegisterStatus } from "../../types";
import CallIcon from "../assets/images/icons/call.svg?inline";
import MedicalFacilityIcon from "../assets/images/icons/medical-facility.svg?inline";
import HouseIcon from "../assets/images/icons/house.svg?inline";
import ChatIcon from "../assets/images/icons/chat.svg?inline";
import VideoIcon from "../assets/images/icons/video.svg?inline";

export const registerStateMap: Array<RegisterStatus> = [
  "NO_ACCOUNT",
  "PERSONAL",
  "CONTRACT",
  "SPECIALIZATIONS",
  "WAITING_FOR_ACCEPT",
  "FULLY_REGISTERED",
];

export const visitTypesIconMap = new Map([
  ["HOUSE", HouseIcon],
  ["CALL", CallIcon],
  ["STATIONARY", MedicalFacilityIcon],
  ["CHAT", ChatIcon],
  ["VIDEO", VideoIcon],
]);

import { createI18n } from "vue-i18n";

import pl from "./pl.json";

export function setI18nLanguage(locale: string) {
  const html = document.querySelector("html") as HTMLElement;
  html.setAttribute("lang", locale);
}

export function setupI18n(locale = "pl-PL") {
  const i18n = createI18n({
    locale,
    fallbackLocale: "en-US",
    warnHtmlInMessage: false,
    escapeParameter: true,
    legacy: false,
    numberFormats: {
      "pl-PL": {
        numeric: {
          style: "float",
        },
        currency: {
          style: "currency",
          currency: "PLN",
          currencyDisenay: "symbol",
        },
      },
    },
    datetimeFormats: {
      "pl-PL": {
        dayAndDate: {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          weekday: "long",
        },
        date: {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        },
        time: {
          hour: "numeric",
          minute: "numeric",
        },
      },
    },
    messages: {
      "pl-PL": pl,
    },
  });
  setI18nLanguage(locale);
  return i18n;
}

import moment, { Moment } from "moment";
import { Visit } from "../../../types";
import { getPatient } from "./patients";

let date: Moment = moment();
const remainder = 15 - (date.minute() % 15);
date.add(remainder, "minutes");
date.add(15, "minutes");

export const paidVisits: Array<Visit> = [
  {
    id: 0,
    status: "PAID",
    from: moment().add(5, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().add(20, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(0),
        aureroVisitId: 0,
        aureroVisitStatus: "INIT",
        symptoms: "test",
      },
      {
        ...getPatient(1),
        aureroVisitId: 101,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "test",
      },
      {
        ...getPatient(2),
        aureroVisitId: 102,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      type: "HOUSE",
      contractorName: "HomeDoctor",
      address: {
        street: "Aleje Jerozolimskie",
        houseNumber: "54",
        postCode: "00-024",
        city: "Warszawa",
      },
      specialization: "SURGEON",
      nfzVisit: true,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 129,
      type: "CONTRACT",
      status: "ACCEPTED",
    },
  },
  {
    id: 1,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(0),
        aureroVisitId: 1,
        aureroVisitStatus: "INIT",
        symptoms: "",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: true,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "PAID",
      status: "ACCEPTED",
    },
  },
  {
    id: 2,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "UA",
    countryOfResidence: "UKR",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 2,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CHAT",
      specialization: "SURGEON",
      nfzVisit: true,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL",
      status: "ACCEPTED",
    },
  },
  {
    id: 3,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(2, "hour").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "UA",
    countryOfResidence: "",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 3,
        aureroVisitStatus: "INIT",
        symptoms:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
    ],
    details: {
      type: "CALL",
      contractorName: "HomeDoctor",
      address: {
        street: "Aleje Jerozolimskie",
        houseNumber: "54",
        postCode: "00-024",
        city: "Warszawa",
      },
      specialization: "DIETICIAN",
      nfzVisit: true,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 129,
      type: "CASH",
      status: "NOT_PAID",
    },
  },
  {
    id: 4,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "UKR",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 4,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "STATIONARY",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL",
      status: "REJECTED",
    },
  },
  {
    id: 5,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 5,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Luxmed",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "EXTERNAL",
      status: "REJECTED",
    },
  },
  {
    id: 6,
    status: "FINISHED",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "UA",
    countryOfResidence: "UKR",
    patients: [
      {
        ...getPatient(0),
        aureroVisitId: 6,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Luxmed",
      type: "CALL",
      specialization: "NEUROLOGIC",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL_BY_LINK",
      status: "REJECTED",
    },
  },
  {
    id: 7,
    status: "PAID",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(0),
        aureroVisitId: 7,
        aureroVisitStatus: "IN_PROGRESS",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL_BY_LINK",
      status: "REJECTED",
    },
  },
];

date = moment("2021-08-20T10:00:00", "YYYY-MM-DDTHH:mm:ss");

export const finishedVisits: Array<Visit> = [
  {
    id: 8,
    status: "FINISHED",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 8,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
      {
        ...getPatient(2),
        aureroVisitId: 8,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "HomeDoctor",
      type: "HOUSE",
      address: {
        street: "Aleje Jerozolimskie",
        houseNumber: "54",
        postCode: "00-024",
        city: "Warszawa",
      },
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
      specialization: "NEUROLOGIC",
    },
    payment: {
      patientPrice: 129,
      type: "CONTRACT",
      status: "ACCEPTED",
    },
  },
  {
    id: 9,
    status: "FINISHED",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "UA",
    countryOfResidence: "UKR",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 9,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Luxmed",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "CONTRACT",
      status: "ACCEPTED",
    },
  },
  {
    id: 10,
    status: "FINISHED",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 10,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CHAT",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "CONTRACT",
      status: "ACCEPTED",
    },
  },
];

export const canceledByDoctorVisits: Array<Visit> = [
  {
    id: 11,
    status: "CANCELLED_BY_DOCTOR",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "UA",
    countryOfResidence: "UKR",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 11,
        aureroVisitStatus: "FINISHED",
        symptoms: "",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "VIDEO",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL",
      status: "ACCEPTED",
    },
  },
  {
    id: 12,
    status: "CANCELLED_BY_DOCTOR",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 12,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL",
      status: "ACCEPTED",
    },
  },
  {
    id: 13,
    status: "CANCELLED_BY_DOCTOR",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 13,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Luxmed",
      type: "VIDEO",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL",
      status: "ACCEPTED",
    },
  },
];

export const finishedNoContactVisits: Array<Visit> = [
  {
    id: 14,
    status: "FINISHED_NO_CONTACT",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(1),
        aureroVisitId: 14,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "HomeDoctor",
      type: "HOUSE",
      address: {
        street: "Aleje Jerozolimskie",
        houseNumber: "54",
        postCode: "00-024",
        city: "Warszawa",
      },
      nfzVisit: true,
      nfzProductCode: "5.01.00.0000152",
      specialization: "NEUROLOGIC",
    },
    payment: {
      patientPrice: 129,
      type: "EXTERNAL",
      status: "REJECTED",
    },
  },
  {
    id: 15,
    status: "FINISHED_NO_CONTACT",
    from: date.format("YYYY-MM-DDTHH:mm:ss"),
    to: date.add(15, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(0),
        aureroVisitId: 15,
        aureroVisitStatus: "FINISHED",
        symptoms: "",
      },
    ],
    details: {
      contractorName: "Luxmed",
      type: "CALL",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "EXTERNAL",
      status: "REJECTED",
    },
  },
  {
    id: 16,
    status: "FINISHED_NO_CONTACT",
    from: null,
    to: null,
    preferredVisitLanguage: "PL",
    countryOfResidence: "POL",
    patients: [
      {
        ...getPatient(2),
        aureroVisitId: 16,
        aureroVisitStatus: "FINISHED",
        symptoms: "test",
      },
    ],
    details: {
      contractorName: "Medicover",
      type: "CHAT",
      specialization: "SURGEON",
      nfzVisit: false,
      nfzProductCode: "5.01.00.0000152",
    },
    payment: {
      patientPrice: 69,
      type: "INTERNAL_BY_LINK",
      status: "REJECTED",
    },
  },
];

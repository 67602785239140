import { Billing } from "../../../types";

const billing: Billing = {
  visitsSettlements: {
    CALL: {
      DAY: 1,
      WEEKEND_DAY: 2,
      HOLIDAY: 3,
    },
    CALL_SPECIALIST: {
      DAY: 4,
      WEEKEND_DAY: 5,
      HOLIDAY: 6,
    },
    VIDEO: {
      DAY: 7,
      WEEKEND_DAY: 8,
      HOLIDAY: 9,
    },
    VIDEO_SPECIALIST: {
      DAY: 11,
      WEEKEND_DAY: 12,
      HOLIDAY: 21,
    },
    CHAT: {
      DAY: 3,
      WEEKEND_DAY: 213,
      HOLIDAY: 32,
    },
    CHAT_SPECIALIST: {
      DAY: 2,
      WEEKEND_DAY: 23,
      HOLIDAY: 4,
    },
    HOUSE: {
      DAY: 43,
      WEEKEND_DAY: 3,
      HOLIDAY: 0,
    },
    STATIONARY: {
      DAY: 2,
      WEEKEND_DAY: 4,
      HOLIDAY: 30,
    },
    STATIONARY_SPECIALIST: {
      DAY: 3,
      WEEKEND_DAY: 3,
      HOLIDAY: 34,
    },
  },
  hoursSettlements: {
    CALL: {
      DAY: 3,
      WEEKEND_DAY: 3,
      HOLIDAY: 22,
    },
    HOUSE: {
      DAY: 30,
      WEEKEND_DAY: 4,
      HOLIDAY: 4,
    },
    STATIONARY: {
      DAY: 5,
      WEEKEND_DAY: 6,
      HOLIDAY: 7,
    },
  },
};

export default billing;
